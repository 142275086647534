<div>
    <app-header></app-header>
    <div class="headerHeight"></div>

    <div class="content" [formGroup]="form">
        
        <!--  -->
        <section class="p1">
            <div class="p1i d-flex w-100">
                <div class="container">
                    <div class="row h-100" style="padding-bottom: 5px;">
                        <div class="d-none d-lg-flex col-lg-6 h-100" style="align-items: center;">
                            <img loading="lazy" src="/assets/images/screens-backchain.webp" style="width: 100%; max-width: 480px;" alt="">
                        </div>
                        <div class="col-sm-12 col-lg-6 h-100 d-flex" style="justify-content: center; flex-direction: column;">
                            <span class="p1Title">Seus backups estão corretos, válidos e disponíveis?</span>
                            <span class="p1SubTitle">
                                Imagine um sistema baseado em Blockchain que possa garantir a integridade, disponibilidade e qualidade de todos os arquivos e informações da sua empresa, de forma redundante e imutável?
                                <br>
                                Agora imagine até os ativos digitais da sua empresa protegidos, em uma infraestrutura segura, privada e desconectada da internet, através de tecnologia inovadora… Quanto custa essa tranquilidade?
                                <br>
                                Conheça o Backchain
                            </span>
                            <div class="d-flex w-100 justify-content-center justify-content-md-end" style="margin-top: 30px;">
                                <!-- <div class="btnGreen" style="margin-right: 13px;">
                                    <div class="icon"><ion-icon name="lock-closed"></ion-icon></div>
                                    100% Seguro
                                </div> -->
                                <div class="btnGreen" (click)="goSection(5)">
                                    <div class="icon"><ion-icon name="mail"></ion-icon></div>
                                    Quero falar com um especialista
                                </div>
                            </div>
                        </div>
                    </div>
                </div>            
            </div>
        </section>

        <!--  -->
        <section class="p2">

            <div style="position: relative;">
            <div #partWhat style="position: absolute; margin-top: -120px;"></div>
            </div>

            <!-- <div style="margin-top: -90px;">
                <div class="container">
                    <div class="row">
                        <div class="col-sm-12 col-md-4">
                            <div class="cardP2">
                                <div class="w-100 d-flex justify-content-center">
                                    <img loading="lazy" src="/assets/images/icon-1-100px.webp" alt="">
                                </div>
                                <div class="w-100 d-flex justify-content-center">
                                    <span class="cardP2Title">Totalmente Online</span>
                                </div>
                                <div class="w-100 d-flex justify-content-center">
                                    <span class="cardP2SubTitle">Dispensa investimentos em infra e capacitação de profissionais para manutenção do sistema</span>
                                </div>
                            </div>
                        </div>
                        
                        <div class="col-sm-6 col-md-4">
                            <div class="cardP2">
                                <div class="w-100 d-flex justify-content-center">
                                    <img loading="lazy" src="/assets/images/icon-2-100px.webp" alt="">
                                </div>
                                <div class="w-100 d-flex justify-content-center">
                                    <span class="cardP2Title">100% Integrado</span>
                                </div>
                                <div class="w-100 d-flex justify-content-center">
                                    <span class="cardP2SubTitle">Informações compartilhadas e totalmente integradas entre Fluxo de Processo, PFMEA e Plano de Controle</span>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-6 col-md-4">
                            <div class="cardP2">
                                <div class="w-100 d-flex justify-content-center">
                                    <img loading="lazy" src="/assets/images/icon-3-100px.webp" alt="">
                                </div>
                                <div class="w-100 d-flex justify-content-center">
                                    <span class="cardP2Title">Sempre Atualizado</span>
                                </div>
                                <div class="w-100 d-flex justify-content-center">
                                    <span class="cardP2SubTitle">Atendimento as normas técnicascomo IATF 16949, ISO 9001 e ISO/IEC 27001</span>
                                </div>
                                 <div #partWhat></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->

            <span class="p2Title">O que é o BackChain?</span>
            <div class="w-100 d-flex justify-content-center" style="padding-bottom: 15px; padding-top: 10px;">
                <img loading="lazy" src="assets/icon/div.webp" style="height: 12px;" alt="div">
            </div>
            <!-- <span class="p2SubTitle container">Entenda como o BackChain vai aprimorar a gestão de seus backups</span> -->

            <div class="container mt-4">
                <div class="row">
                    <!-- <div class="col-12 col-lg-3 d-none d-lg-flex">
                        <img src="assets/images/logo-backchain-152x176.webp" alt="">
                    </div> -->
                    <div class="col-12 col-lg-12 p2text text-center" style="font-size: 18px;">
                        O <b>BackChain</b> é um poderoso sistema baseado na tecnologia Blockchain que valida, registra e documenta todos os seus <br> backups de forma rápida e segura, trazendo confiabilidade e tranquilidade ao seu negócio. <br> É <b>impossível</b> prever um desastre. <br> Mas é <b>possível</b> acelerar a sua recuperação!
                    </div>
                </div>
            </div>

            <div #partDif></div>
        </section>

        <!--  -->
        <section class="pGray">
            <div style="height: 1px;"></div>

            <span class="p2Title">Sistema de backup em Blockchain</span>
            <div class="w-100 d-flex justify-content-center" style="padding-bottom: 15px; padding-top: 10px;">
                <img loading="lazy" src="assets/icon/div.webp" style="height: 12px;" alt="">
            </div>
            <span class="p2SubTitle container">Confira algumas características que tornam o BackChain único.</span>
            <br><br><br>

            <div class="container">
                <div class="row">
                    <!-- <div class="col-12 col-lg-2 d-none d-lg-flex">
                        <img src="assets/images/logo-backchain-152x176.webp" height="180px" alt="">
                    </div> -->
                    <div class="col-12 col-lg-12 p2text text-center" style="font-size: 18px;">
                        Ao gerar backups no BackChain, são atribuídas propriedades de identificação e preservação aos dados, portanto, mesmo que alguém intercepte a transmissão, não conseguirá lê-la, pois os dados serão criptografados, o que garante a imutabilidade desde sua origem até a restauração enquanto mantendo um alto nível de confiança, integridade e autenticidade.
                        <br>
                        Com uma interface web amigável, você pode solicitar uma restauração a qualquer momento, ou ainda monitorar o andamento dos seus backups e a integridade de cada arquivo, além de poder visualizar diversos indicadores, como o ciclo de vida de cada arquivo copiado. o BackChain.
                    </div>
                </div>
                <div #partQ></div>
            </div>

        </section>

        <!--  -->
        <!-- <section class="pBlue">
            <div class="pBluei">
                <div class="container h-100">
                    <div class="row h-100" style="display: flex; align-items: center;">
                        <div class="col-12 col-md-8" style="display: flex; flex-direction: column; justify-content: center;">
                            <span class="pBlueTitle">Nós estamos <br> à disposição</span>
                            <span class="pBlueSubTitle mt-4">Fale com nossos especialistas. <br> Pergunte, tire suas dúvidas e conheça nosso time!</span>
                        </div>
                        <div class="col-12 col-md-4"  style="display: flex; flex-direction: column; justify-content: center;">
                            <br class="d-flex d-md-none">
                            <br class="d-flex d-md-none">
                            <button (click)="goSection(5)" class="btnPBlue">Fale Conosco</button>
                        </div>
                    </div>
                </div>
                <div #partQ></div>
            </div>
        </section> -->

        <!--  -->
        <section class="pWhite">
            <div style="height: 1px;"></div>

            <span class="p2Title">Mas por que escolher o backchain?</span>
            <div class="w-100 d-flex justify-content-center" style="padding-bottom: 15px; padding-top: 10px;">
                <img loading="lazy" src="assets/icon/div.webp" style="height: 12px;" alt="">
            </div>
            <span class="p2SubTitle container" style="line-height: 27px;">BackChain fornece proteção de dados fácil, completa e segura para todo o seu ambiente – unificando o backup de seus sistemas locais e cargas de trabalho em uma estrutura redundante robusta e de alta capacidade.</span>

            <div class="container mt-5">
                <div class="row">
                    <div class="col-12 col-md-6">

                        <div>
                            <span class="whyTitle"><ion-icon name="checkmark"></ion-icon> Rastreabilidade</span>
                            <span class="whySubTitle">Todas as transações são feitas utilizando a tecnologia Blockchain, garantindo a integridade dos arquivos.</span>
                        </div>

                        <div>
                            <span class="whyTitle"><ion-icon name="checkmark"></ion-icon> Disponibilidade e acessibilidade</span>
                            <span class="whySubTitle">Como são distribuídos em várias instâncias, os backups do BackChain podem ser acessados a qualquer momento.</span>
                        </div>

                    </div>
                    <div class="col-12 col-md-6">

                        <div>
                            <span class="whyTitle"><ion-icon name="checkmark"></ion-icon> Redundância</span>
                            <span class="whySubTitle">Os backups são espelhados em mais de uma instância, garantindo a recuperação total dos dados da sua empresa.</span>
                        </div>

                        <div>
                            <span class="whyTitle"><ion-icon name="checkmark"></ion-icon> Recuperação de desastres</span>
                            <span class="whySubTitle">O usuário tem a tranquilidade de saber que embora esteja sujeito a incidentes, poderá se recuperar rapidamente.</span>
                        </div>

                    </div>
                </div>
                <!-- <div #partPlans></div> -->
            </div>

        </section>

        <!--  -->
        <section class="pBlue">
            <div class="pBluei">
                <div class="container h-100">
                    <div class="h-100" style="display: flex; align-items: center;">
                        <div class="w-100" style="display: flex; flex-direction: column; justify-content: center; text-align: center; align-items: center;">
                            <span class="pBlueTitle d-block text-center" style="font-size: 32px; line-break: anywhere;">#TheBackChainInNumbers</span>
                            <div class="w-100 row">
                                <div class="col-12 col-md-4 mt-4">
                                    <span class="pBPorc">90%</span>
                                    <span class="d-block pBPorcTxt">de Economia de Energia</span>
                                </div>
                                <div class="col-12 col-md-4 mt-4">
                                    <span class="pBPorc">100%</span>
                                    <span class="d-block pBPorcTxt">de integridade de dados</span>
                                </div>
                                <div class="col-12 col-md-4 mt-4">
                                    <span class="pBPorc">100%</span>
                                    <span class="d-block pBPorcTxt">rastreabilidade de Documentos</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div #partPerg></div> -->
                <div #partPlans></div>
            </div>
        </section>

        <!--  -->
        <section class="pGray">
            <div style="height: 1px;"></div>

            <span class="p2Title">Investimento e Economia</span>
            <div class="w-100 d-flex justify-content-center" style="padding-bottom: 15px; padding-top: 10px;">
                <img loading="lazy" src="assets/icon/div.webp" style="height: 12px;" alt="">
            </div>
            <span class="p2SubTitle container">Como um investimento pode trazer economia para sua empresa?</span>

            <br>
            <div class="container mt-5">

                <div>
                    <span class="whyTitle"><ion-icon name="leaf-outline"></ion-icon> Eficiência energética</span>
                    <span class="whySubTitle">Um computador típico consome cerca de 300 watts/hora, nossos aparelhos consomem 4 watts/hora. Se levarmos em conta um período de 12 meses, são cerca de <b>2.500.000 watts de economia.</b> </span>
                </div>

                <div>
                    <span class="whyTitle"><ion-icon name="desktop-outline"></ion-icon> Infraestrutura robusta, mas descentralizada</span>
                    <span class="whySubTitle">Sua empresa contará com uma estrutura equivalente a um Data-Center, distribuída através de servidores localizados ao redor do mundo.</span>
                </div>

                <div>
                    <span class="whyTitle"><ion-icon name="code-slash-outline"></ion-icon> Criptografia ponta a ponta</span>
                    <span class="whySubTitle">Uma conexão <b>única e exclusiva</b>, feita sobre SSL, com armazenamento <b>redundante e criptografado</b>, certifica que todas as informações transacionais entre as entidades estarão <b>íntegras e seguras.</b> </span>
                </div>

                <div>
                    <span class="whyTitle"><ion-icon name="globe-outline"></ion-icon> Tecnologia Blockchain</span>
                    <span class="whySubTitle">Cada cópia realizada pelo nosso sistema gerará dados distribuídos que mantêm uma cadeia crescente de registros de transações a partir de arquivos copiados. A natureza distribuída da cadeia impede alterações e revisões, o que facilita a confirmação da autenticidade e segurança de todas as transações registadas na cadeia.</span>
                </div>

                <div>
                    <span class="whyTitle"><ion-icon name="key-outline"></ion-icon> Relatórios Indicativos</span>
                    <span class="whySubTitle">Os indicadores gerados pelo BackChain podem contribuir para a tomada de decisões e otimização de todos os recursos da sua organização.</span>
                </div>
                
            </div>

        </section>

        <!--  -->
        <section class="pWhite">
            <div style="height: 1px;"></div>

            <div class="container mt-5">
                <div class="row">
                    <div class="col-12 col-lg-9 text-end" style="align-items: end; display: flex; justify-content: center; flex-direction: column;">
                        <span class="d-block textDom bold">“Fácil de usar, gerenciar e sempre disponível!</span>
                        <span class="d-block textDom">Escolhemos o BackChain por sua confiabilidade e eficiência. O sistema tem sido essencial para a proteção e acessibilidade de nossos dados. O suporte técnico é rápido e prestativo, garantindo que nossos sistemas estejam sempre protegidos. Recomendo fortemente os serviços para qualquer empresa que valorize a integridade dos seus dados.”</span>
                        <span class="d-block textDom bolder">Eduardo Antão</span>
                        <span class="d-block textDom bold" style="margin-top: 0;">CEO Control Service Brasil</span>
                    </div>
                    <div class="d-none d-lg-block col-lg-3">
                        <img src="assets/images/8.webp" style="width: 273px; border-radius: 100%;" alt="">
                    </div>
                </div>
            </div>

        </section>

        <!--  -->
        <section class="pBlue">
            <div class="pBluei">
                <div class="container h-100">
                    <div class="row h-100" style="display: flex; align-items: center;">
                        <div class="col-12" style="display: flex; flex-direction: column; justify-content: center; text-align: center; align-items: center;">
                            <span class="pBlueTitle d-block text-center" style="font-size: 32px;">Como o BlockChain pode ajudar você?</span>
                            <span class="pBlueSubTitle mt-4">Uma nova maneira de gerenciar sua qualidade.</span>
                            <div style="width: 55px; height: 55px; display: flex; justify-content: center; align-items: center; background-color: white; border-radius: 100%; margin-top: 40px; cursor: pointer" (click)="openLink('https://www.youtube.com/watch?v=sNIpDZxH6mY')">
                                <ion-icon name="play" style="color: #fa9310; font-size: 27px; margin-left: 4px; margin-top: 2px;"></ion-icon>
                            </div>
                        </div>
                    </div>
                </div>
                <div #partPerg></div>
            </div>
        </section>

        <!--  -->
        <section class="pWhite">
            <div style="height: 1px;"></div>

            <span class="p2Title">Modelo de Negócios</span>
            <div class="w-100 d-flex justify-content-center" style="padding-bottom: 15px; padding-top: 10px;">
                <img loading="lazy" src="assets/icon/div.webp" style="height: 12px;" alt="">
            </div>
            <!-- <span class="p2SubTitle container">Vamos aos fatos... Você já passou por esses problemas?</span> -->

            <div class="container mt-5">
                <div class="row">

                    <div class="col-12 col-md-6">
                        <div>
                            <span class="whyTitle">1. Escolha o plano</span>
                            <span class="whySubTitle">O BackChain pode ser contratado em 05 versões, dependendo da sua necessidade de armazenamento, e como diferencial de mercado, não cobra tráfego de dados, seja no backup ou na restauração de dados.</span>
                        </div>
                    </div>

                    <div class="col-12 col-md-6">
                        <div style="width: 100%; display: flex; justify-content: center;">
                            <img src="assets/images/logo-backchain-152x176.webp" alt=""  style="max-width: 100%;">
                        </div>
                    </div>

                    <div class="col-12 col-md-12">
                        <div style="width: 100%; display: flex; justify-content: center;">
                            <img src="assets/images/connector.webp" alt=""  style="max-width: 100%;">
                        </div>
                    </div>

                    <div class="col-12 col-md-6">
                        <div style="width: 100%; display: flex; justify-content: center;">
                            <img src="assets/images/como-funciona-2.webp" alt=""  style="max-width: 100%;">
                        </div>
                    </div>

                    <div class="col-12 col-md-6">

                        <div>
                            <span class="whyTitle">2. Estrutura Inicial</span>
                            <span class="whySubTitle">Enviaremos nosso Appliance para sua empresa, que deverá estar conectado à sua rede interna. Após a configuração inicial e validação em blockchain, seu sistema de backup estará disponível para uso.</span>
                        </div>

                    </div>

                    <div class="col-12 col-md-12">
                        <div style="width: 100%; display: flex; justify-content: center;">
                            <img src="assets/images/connector-inverted.webp" alt="" style="max-width: 100%;">
                        </div>
                    </div>

                    <div class="col-12 col-md-6">

                        <div>
                            <span class="whyTitle">3. Configuração de instâncias redundantes</span>
                            <span class="whySubTitle">Nossa equipe de especialistas definirá as rotinas de performance de backup de acordo com as necessidades específicas do seu negócio/infraestrutura.</span>
                        </div>

                    </div>

                    <div class="col-12 col-md-6">
                        <div style="width: 100%; display: flex; justify-content: center; align-items: center;">
                            <img src="assets/images/como-funciona-3.webp" alt=""  style="max-width: 100%;">
                        </div>
                    </div>

                    <div class="col-12 col-md-12">
                        <div style="width: 100%; display: flex; justify-content: center;">
                            <img src="assets/images/connector.webp" alt=""  style="max-width: 100%;">
                        </div>
                    </div>

                    <div class="col-12 col-md-6">
                        <div style="width: 100%; display: flex; justify-content: center;">
                            <img src="assets/images/como-funciona-4.webp" alt=""  style="max-width: 100%;">
                        </div>
                    </div>

                    <div class="col-12 col-md-6">

                        <div>
                            <span class="whyTitle">4. Monitorando backups</span>
                            <span class="whySubTitle">Após a configuração manteremos o monitoramento para garantir o andamento, garantindo a integridade dos dados que estão sendo armazenados.</span>
                        </div>

                    </div>

                </div>
                <div #partPlans></div>
            </div>

        </section>

        <!--  -->
        <section class="pGray">
            <div style="height: 1px;"></div>

            <div class="container">

                <span class="p2Title">Perguntas Frequentes</span>
                <div class="w-100 d-flex justify-content-center" style="padding-bottom: 15px; padding-top: 10px;">
                    <img loading="lazy" src="assets/icon/div.webp" style="height: 12px;" alt="">
                </div>
                <span class="p2SubTitle container">Ainda tem dúvidas? Veja algumas das perguntas que sempre fazem pra nós!</span>

                <div class="w-100 d-block mt-5">

                    <div class="accor {{accordionOpen == 1 ? 'accorOpen' : ''}}" (click)="openAccor(1)">
                        <div style="display: flex; align-items: center; height: 50px;">
                            <ion-icon name="chevron-down-outline"></ion-icon>
                            <span class="accorTitle">Deve demorar muito para copiar os arquivos?</span>
                        </div>
                        <span class="AccorRes poppins">
                            Os backups são feitos de forma incremental e paralela, através de uma conexão privada através de um túnel criptografado. Ou seja, o backup acontece rapidamente porque acontece em uma conexão exclusiva e copia apenas os documentos que foram alterados ou criados desde o último backup.
                        </span>
                    </div>

                    <div class="accor {{accordionOpen == 2 ? 'accorOpen' : ''}}" (click)="openAccor(2)">
                        <div style="display: flex; align-items: center; height: 50px;">
                            <ion-icon name="chevron-down-outline"></ion-icon>
                            <span class="accorTitle">Ocupa muito espaço?</span>
                        </div>
                        <span class="AccorRes poppins">
                            Toda a estrutura é fornecida em conjunto, você não precisa se preocupar com espaço, tamanho de arquivo ou similares.
                        </span>
                    </div>

                    <div class="accor {{accordionOpen == 3 ? 'accorOpen' : ''}}" (click)="openAccor(3)">
                        <div style="display: flex; align-items: center; height: 50px;">
                            <ion-icon name="chevron-down-outline"></ion-icon>
                            <span class="accorTitle">Se estiver na internet, qualquer pessoa pode acessar?</span>
                        </div>
                        <span class="AccorRes poppins">
                            Este é um dos diferenciais do BackChain. Todo o procedimento é realizado de forma privada, através de uma estrutura “desconectada” da internet comum. A interface que se conecta à internet limita-se apenas a registrar informações e, portanto, não há risco de vazamento de documentos e dados.
                        </span>
                    </div>

                    <div class="accor {{accordionOpen == 4 ? 'accorOpen' : ''}}" (click)="openAccor(4)">
                        <div style="display: flex; align-items: center; height: 50px;">
                            <ion-icon name="chevron-down-outline"></ion-icon>
                            <span class="accorTitle">Como posso ter certeza de que o backup foi feito?</span>
                        </div>
                        <span class="AccorRes poppins">
                            Através de nossa interface web, você poderá verificar a realização e consistência de seus backups.
                        </span>
                    </div>

                    <div class="accor {{accordionOpen == 5 ? 'accorOpen' : ''}}" (click)="openAccor(5)">
                        <div style="display: flex; align-items: center; height: 50px;">
                            <ion-icon name="chevron-down-outline"></ion-icon>
                            <span class="accorTitle">O que acontece se alguém roubar meus dispositivos, essa pessoa terá acesso às minhas informações?</span>
                        </div>
                        <span class="AccorRes poppins">
                            Se por acaso os dispositivos de armazenamento forem roubados, o infrator não terá acesso a nenhuma informação, pois todos os dados são criptografados.
                        </span>
                    </div>

                    <!-- <div class="w-100 w-100 d-flex justify-content-end">
                        <span (click)="faq()" class="compareText poppins mb-3">Ainda tem dúvidas? Veja o FAQ completo </span>
                    </div> -->

                </div>

            </div>
            <div #partCont></div>


        </section>

        <!--  -->
        <section class="pWhite">
            <div style="height: 1px;"></div>

            <span class="p2Title">Entre em contato agora mesmo!</span>
            <div class="w-100 d-flex justify-content-center" style="padding-bottom: 15px; padding-top: 10px;">
                <img loading="lazy" src="assets/icon/div.webp" style="height: 12px;" alt="">
            </div>

            <div class="text-center mt-4">
                <span class="poppins d-flex city"> <ion-icon class="iconLast" name="location-sharp"></ion-icon> São Paulo – Brasil</span>
                <span class="poppins d-flex normal">Avenida Brigadeiro Faria Lima 1811, cj 1109</span>
                <span class="poppins d-flex normal">CEP 01452-001</span>
                <br>
    
                <span class="poppins d-flex normal"> <ion-icon class="iconLast" name="call"></ion-icon> Tel.: (+55) 11 2189 0150</span>
                <br>
    
                <span (click)="sendEmail()" class="poppins d-flex normal blueHover" style="color: black;"> <ion-icon class="iconLast" name="send"></ion-icon> contato@backchain.com.br</span>

                <!-- <div class="d-flex justify-content-center mt-3 mb-3">
                    <div (click)="face()" class="iconDiv"><ion-icon name="logo-facebook"></ion-icon></div>
                    <div (click)="linkedin()" class="iconDiv"><ion-icon name="logo-linkedin"></ion-icon></div>
                    <div (click)="insta()" class="iconDiv"><ion-icon name="logo-instagram"></ion-icon></div>
                    <div (click)="twitter()" class="iconDiv"><ion-icon name="logo-twitter"></ion-icon></div>
                </div> -->

            </div>

        </section>

    </div>

    <app-footer></app-footer>
</div>