import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { SubscribeComponent } from './pages/subscribe/subscribe.component';
import { CompareComponent } from './pages/compare/compare.component';
import { SitemapComponent } from './pages/sitemap/sitemap.component';
import { TermsComponent } from './pages/terms/terms.component';
import { FaqComponent } from './pages/faq/faq.component';
import { TeamComponent } from './pages/team/team.component';
import { PartnersComponent } from './pages/partners/partners.component';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' }, // redirect to `home`

  { path: 'home', component: HomeComponent },
  // { path: 'assine', component: SubscribeComponent },
  // { path: 'faq', component: FaqComponent },
  // { path: 'termos-e-condicoes', component: TermsComponent },
  // { path: 'sitemap', component: SitemapComponent },
  // { path: 'compare', component: CompareComponent },
  // { path: 'nosso-time', component: TeamComponent },
  // { path: 'nossos-parceiros', component: PartnersComponent },

  { path: '**', component: NotFoundComponent },  // Wildcard route for a 404 page
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
